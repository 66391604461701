(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./autoreg.js');

$('.slider').slick({
	autoplay: true
});

$(document).ready(function () {
	$('article').prependTo($('.main-text'));
	$('article h1').appendTo($('.for-h'));

	//menu toggle
	$('#menu-toggle-but').click(function () {
		$(this).toggleClass('open');
		$('.topbar-nav').slideToggle('fast');
	});

	// Popup

	if ($(window).width() > 768) {
		$(document).mouseleave(function (e) {
			var popupCookie = $.cookie('popup');
			if (e.clientY < 10 && popupCookie !== '1') {
				$(".b-popup-wrapp").fadeIn("fast");
				$.cookie('popup', '1');
			}
		});
	}

	$('.b-popup-close').click(function () {
		$(".b-popup-wrapp").fadeOut("fast");
	});
	// $('.b-popup-container').click(function(e) {
	// 	if (e.target === e.currentTarget) {
	// 		$(".b-popup-wrapp").fadeOut("fast");
	// 	}
	// });


	// Mobile popup

	if ($(window).width() <= 768) {

		var mPopupCookie = $.cookie('m-popup');

		if (mPopupCookie !== '1') {
			var oldCount = parseInt($.cookie('m-popup_timer'));
			var counter = oldCount || 0;

			var timer = setInterval(function () {
				counter++;
				// console.log(counter);
				$.cookie('m-popup_timer', counter);

				if (counter >= 15) {
					clearInterval(timer);
					$(".m-popup-wrapp").fadeIn("fast");
					$.cookie('m-popup_timer', 0);
					$.cookie('m-popup', '1');
				}
			}, 1000);
		}
	}
	$('.m-popup-close').click(function () {
		$(".m-popup-wrapp").fadeOut("fast");
	});
	// $('.m-popup-container').click(function(e) {
	// 	if (e.target === e.currentTarget) {
	// 		$(".m-popup-wrapp").fadeOut("fast");
	// 	}
	// });
});

},{"./autoreg.js":2}],2:[function(require,module,exports){
'use strict';

$('[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link), '_self');
});

},{}]},{},[1]);
